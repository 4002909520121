@font-face {
	font-family:"Ionicons";
	src:url(../fonts/ionicons/fonts/ionicons.eot);
	src:url(../fonts/ionicons/fonts/ionicons.eot) format("embedded-opentype"),url(../fonts/ionicons/fonts/ionicons.ttf) format("truetype"),url(../fonts/ionicons/fonts/ionicons.woff) format("woff"),url(../fonts/ionicons/fonts/ionicons.svg) format("svg");
	font-weight:400;
	font-style:normal;
}

/*
@font-face {
	font-family:'FontAwesome';
	src:url(../fonts/fontawesome/fonts/fontawesome-webfont.eot?v=4.7.0);
	src:url(../fonts/fontawesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0) format("embedded-opentype"),url(../fonts/fontawesome/fonts/fontawesome-webfont.woff2?v=4.7.0) format("woff2"),url(../fonts/fontawesome/fonts/fontawesome-webfont.woff?v=4.7.0) format("woff"),url(../fonts/fontawesome/fonts/fontawesome-webfont.ttf?v=4.7.0) format("truetype"),url(../fonts/fontawesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular) format("svg");
	font-weight:400;
	font-style:normal;
}
*/

a {
	color: #212529;
	text-decoration: none;
}
a:hover {
	color: #aecf02;
	text-decoration: none;
}
a, a:hover, a:before, a:hover:before {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	cursor: pointer;
}
.mouse-wrap,.scroll-wrap {
	position:absolute;
	bottom:50px;
	z-index:3;
	left:50%;
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}
.mouse-wrap.left,.scroll-wrap.left {
	left:30px;
	-webkit-transform:translateX(0%);
	-ms-transform:translateX(0%);
	transform:translateX(0%);
}
.mouse {
	width:30px;
	height:50px;
	border-radius:20px;
	border:2px solid #212529;
	position:absolute;
	top:calc(50% - 30px);
	left:calc(50% - 20px);
}
.mouse:before,.mouse:after {
	content:"";
	display:block;
	position:absolute;
}
img {
	max-width: 100%;
}
ul {
	padding-left: 0px;
}

/* Second */
.m-1:before {
	width:6px;
	height:6px;
	border-radius:50%;
	top:10px;
	left:calc(50% - 3px);
	background:#212529;
	-webkit-animation:m-1-ball 2s cubic-bezier(0.215,0.61,0.355,1) infinite;
	animation:m-1-ball 2s cubic-bezier(0.215,0.61,0.355,1) infinite;
}

@-webkit-keyframes m-1-ball {
	0%,65%,100% {
		opacity:0;
	}
	
	10%,40% {
		opacity:1;
	}
	
	0% {
		-webkit-transform:translateY(5px) scale(0.7);
		transform:translateY(5px) scale(0.7);
	}
	
	5% {
		-webkit-transform:scale(0.7);
		transform:scale(0.7);
	}
	
	15%,100% {
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	
	45%,65% {
		-webkit-transform:translateY(24px) scale(0.7);
		transform:translateY(24px) scale(0.7);
	}
}

@keyframes m-1-ball {
	0%,65%,100% {
		opacity:0;
	}
	
	10%,40% {
		opacity:1;
	}
	
	0% {
		-webkit-transform:translateY(5px) scale(0.7);
		transform:translateY(5px) scale(0.7);
	}
	
	5% {
		-webkit-transform:scale(0.7);
		transform:scale(0.7);
	}
	
	15%,100% {
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	
	45%,65% {
		-webkit-transform:translateY(24px) scale(0.7);
		transform:translateY(24px) scale(0.7);
	}
}

.scroll-wrap {
	-webkit-transition:.3s all ease-out;
	-o-transition:.3s all ease-out;
	transition:.3s all ease-out;
	bottom:50px;
	opacity:1;
	visibility:visible;
}

.scroll-wrap.sleep {
	bottom:0;
	opacity:0;
	visibility:hidden;
}

.testimonial_v1 img {
	height:80px;
}

.testimonial_v1 .heading {
	color:#495057;
	font-size:18px;
}

.testimonial_v1 .subheading {
	color:rgba(0,0,0,0.4);
}

.testimonial_v1 blockquote {
	font-family:"Crimson Text",Georgia,"Times New Roman",Times,serif;
	line-height:1.7;
	color:#495057;
}

.testimonial_v1.probootstrap_quote_v1 .media-body {
	position:relative;
	margin-top:40px;
}

.testimonial_v1.probootstrap_quote_v1 .quote {
	font-size:100px;
	font-family:"Crimson Text",Georgia,"Times New Roman",Times,serif;
	color:#adb5bd;
	line-height:0;
	position:absolute;
	left:50%;
	top:0;
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}

.testimonial_v1.probootstrap_quote_v2 .media-body {
	position:relative;
}

.testimonial_v1.probootstrap_quote_v2 .quote {
	font-size:150px;
	font-family:"Crimson Text",Georgia,"Times New Roman",Times,serif;
	color:#dee2e6;
	line-height:0;
	top:40px;
	position:absolute;
	left:0;
	z-index:-1;
}

.testimonial_v2 .probootstrap_media-header img {
	height:70px;
}

.testimonial_v2 .probootstrap_media-header .heading {
	font-size:18px;
}

.testimonial_v2 .probootstrap_media-header .heading,.testimonial_v2 .probootstrap_media-header .subheading {
	display:block;
	margin-bottom:0;
}

.testimonial_v2 blockquote {
	font-family:"Crimson Text",Georgia,"Times New Roman",Times,serif;
	font-style:italic;
	font-size:18px;
	line-height:1.7;
	color:#495057;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_content .slick-slide {
	padding-top:40px;
	padding-bottom:40px;
	font-size:30px;
	line-height:1;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_content .slick-slide blockquote {
	margin-bottom:40px;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_content .slick-slide img {
	height:170px;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_content .slick-slide .heading {
	font-size:18px;
	margin-bottom:0;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_content .slick-slide .subheading {
	font-size:16px;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_nav {
	width:250px;
	margin:0 auto;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_nav .author {
	cursor:pointer;
	padding-top:10px;
	padding-bottom:10px;
	margin:0 10px;
	-webkit-transition:.3s all ease-in;
	-o-transition:.3s all ease-in;
	transition:.3s all ease-in;
}

.probootstrap_slide_testimonial_sync_v1 .probootstrap_slider_nav .author.slick-current {
	-webkit-transform:scale(1.2);
	-ms-transform:scale(1.2);
	transform:scale(1.2);
}

.probootstrap_pricing_v1 {
	margin-bottom:50px;
}

.probootstrap_pricing_v1 .price {
	font-size:50px;
	color:#aecf02;
}

.probootstrap_pricing_v1 .price sup {
	font-size:20px;
	top:-1em;
}

.probootstrap_pricing_v1 .price span {
	font-size:20px;
	color:#adb5bd;
}

.probootstrap_feature-v1,.probootstrap_feature-v2 {
	margin-bottom:30px;
/* circle with border */
}

.probootstrap_feature-v1 .probootstrap_icon i,.probootstrap_feature-v2 .probootstrap_icon i {
	font-size:70px;
}

.probootstrap_feature-v1 .media-body,.probootstrap_feature-v2 .media-body {
	font-family:"Crimson Text",Georgia,"Times New Roman",Times,serif;
	font-size:18px;
	color:#868e96;
}

.probootstrap_feature-v1 .media-body .heading,.probootstrap_feature-v2 .media-body .heading {
	font-size:20px;
	font-family:"Montserrat",Arial,sans-serif;
}

.probootstrap_feature-v1.probootstrap_feature-circle,.probootstrap_feature-v2.probootstrap_feature-circle {
/* circle with background color */
}

.probootstrap_feature-v1.probootstrap_feature-circle .probootstrap_icon,.probootstrap_feature-v2.probootstrap_feature-circle .probootstrap_icon {
	line-height:0;
	border-radius:50%;
	width:80px;
	height:80px;
	position:relative;
	border:1px solid #dee2e6;
	margin-bottom:30px;
}

.probootstrap_feature-v1.probootstrap_feature-circle .probootstrap_icon i,.probootstrap_feature-v2.probootstrap_feature-circle .probootstrap_icon i {
	line-height:0;
	font-size:45px;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translateX(-50%) translateY(-50%);
	-ms-transform:translateX(-50%) translateY(-50%);
	transform:translateX(-50%) translateY(-50%);
}

.probootstrap_feature-v1.probootstrap_feature-circle.probootstrap_feature-colored .probootstrap_icon,.probootstrap_feature-v2.probootstrap_feature-circle.probootstrap_feature-colored .probootstrap_icon {
	border:none;
	background:#aecf02;
}

.probootstrap_feature-v1.probootstrap_feature-circle.probootstrap_feature-colored .probootstrap_icon i,.probootstrap_feature-v2.probootstrap_feature-circle.probootstrap_feature-colored .probootstrap_icon i {
	color:#fff;
}

.probootstrap_feature-v2 .probootstrap_icon {
	line-height:0;
	position:relative;
	text-align:center;
}

.probootstrap_feature-v2 .probootstrap_icon i {
	font-size:36px;
	margin:0 auto;
}

.probootstrap_list_v1 li {
	margin-bottom:7px;
}

.probootstrap_list_v1 li span {
	line-height:0;
	margin-right:7px;
	color:#aecf02;
}

.probootstrap_list_v1 li span i {
	line-height:0;
}

.probootstrap_list_v1 li span i:before {
	line-height:0;
}

.probootstrap_team_v1 {
	margin-bottom:50px;
}

@media (max-width: 991px) {
	.probootstrap_team_v1 {
		text-align:center;
	}
}

.probootstrap_team_v1 img {
	height:200px;
}

@media (max-width: 991px) {
	.probootstrap_team_v1 img {
		margin:0 auto;
	}
}

.probootstrap_team_v1 .heading {
	color:#000;
	font-size:24px;
}

.probootstrap_team_v1 .subheading {
	color:#adb5bd;
}

.probootstrap_sm_py_cover,.probootstrap_md_py_cover,.probootstrap_lg_py_cover,.probootstrap_xl_py_cover {
	position:relative;
	padding:6em 0;
}

@media (max-width: 767px) {
	.probootstrap_sm_py_cover,.probootstrap_md_py_cover,.probootstrap_lg_py_cover,.probootstrap_xl_py_cover {
		height:inherit;
		text-align:center!important;
		padding:6em 0;
	}
}

.probootstrap_sm_py_cover > .container,.probootstrap_md_py_cover > .container,.probootstrap_lg_py_cover > .container,.probootstrap_xl_py_cover > .container {
	position:relative;
}

.probootstrap_sm_py_cover > .container > .row,.probootstrap_md_py_cover > .container > .row,.probootstrap_lg_py_cover > .container > .row,.probootstrap_xl_py_cover > .container > .row {
	padding:6em 0;
}

@media (max-width: 767px) {
	.probootstrap_sm_py_cover > .container > .row,.probootstrap_md_py_cover > .container > .row,.probootstrap_lg_py_cover > .container > .row,.probootstrap_xl_py_cover > .container > .row {
		height:inherit;
		padding:6em 0;
	}
}

.probootstrap_sm_py_cover .heading,.probootstrap_md_py_cover .heading,.probootstrap_lg_py_cover .heading,.probootstrap_xl_py_cover .heading {
	font-size:62px;
	color:#fff;
	font-weight:300;
	margin-top:20px;
	margin-bottom:0;
}

@media (max-width: 767px) {
	.probootstrap_sm_py_cover .heading,.probootstrap_md_py_cover .heading,.probootstrap_lg_py_cover .heading,.probootstrap_xl_py_cover .heading {
		font-size:42px;
	}
}

.probootstrap_sm_py_cover .sub-heading,.probootstrap_md_py_cover .sub-heading,.probootstrap_lg_py_cover .sub-heading,.probootstrap_xl_py_cover .sub-heading {
	font-size:20px;
	color:rgba(255,255,255,0.8);
	max-width:600px;
	float:left;
}

.probootstrap_sm_py_cover.text-center .sub-heading,.probootstrap_md_py_cover.text-center .sub-heading,.probootstrap_lg_py_cover.text-center .sub-heading,.probootstrap_xl_py_cover.text-center .sub-heading {
	margin:0 auto;
	float:none;
}

.probootstrap_sm_py_cover.text-right .sub-heading,.probootstrap_md_py_cover.text-right .sub-heading,.probootstrap_lg_py_cover.text-right .sub-heading,.probootstrap_xl_py_cover.text-right .sub-heading {
	float:right;
}

.probootstrap_sm_py_cover.text-left .sub-heading,.probootstrap_md_py_cover.text-left .sub-heading,.probootstrap_lg_py_cover.text-left .sub-heading,.probootstrap_xl_py_cover.text-left .sub-heading {
	float:left;
}

.probootstrap_sm_py_cover {
	padding:3em 0;
}

@media (max-width: 767px) {
	.probootstrap_sm_py_cover {
		height:inherit;
		text-align:center!important;
		padding:3em 0;
	}
}

.probootstrap_sm_py_cover > .container {
	position:relative;
}

.probootstrap_sm_py_cover > .container > .row {
	padding:3em 0;
}

@media (max-width: 767px) {
	.probootstrap_sm_py_cover > .container > .row {
		height:inherit;
		padding:3em 0;
	}
}

.probootstrap_lg_py_cover {
	padding:8em 0;
}

@media (max-width: 767px) {
	.probootstrap_lg_py_cover {
		height:inherit;
		text-align:center!important;
		padding:8em 0;
	}
}

.probootstrap_lg_py_cover > .container {
	position:relative;
}

.probootstrap_lg_py_cover > .container > .row {
	padding:4em 0;
}

@media (max-width: 767px) {
	.probootstrap_lg_py_cover > .container > .row {
		height:inherit;
		padding:4em 0;
	}
}

.probootstrap_xl_py_cover {
	padding:10em 0;
}

@media (max-width: 767px) {
	.probootstrap_xl_py_cover {
		height:inherit;
		text-align:center!important;
		padding:10em 0;
	}
}

.probootstrap_xl_py_cover > .container {
	position:relative;
}

.probootstrap_xl_py_cover > .container > .row {
	padding:7em 0;
}

@media (max-width: 767px) {
	.probootstrap_xl_py_cover > .container > .row {
		height:inherit;
		padding:7em 0;
	}
}

.probootstrap_cover,.probootstrap_cover_v1,.probootstrap_cover_v2,.probootstrap_cover_v3,.probootstrap_cover_v4 {
	position:relative;
	color: #ffffff;
}
.probootstrap_cover > .container > .row {
	height:95vh;
}

.probootstrap_cover_v1 {
	position:relative;
	height:100vh;
}
.probootstrap_cover_v1 > .container > .row {
	height:95vh;
}

.probootstrap_cover_v2 {
	position:relative;
	height:72vh;
}
.probootstrap_cover_v2 > .container > .row {
	height:72vh;
}



#section-home {
	background-position: 50% 50%;
}

@media (max-width: 767px) {
	.probootstrap_cover,.probootstrap_cover_v1,.probootstrap_cover_v2,.probootstrap_cover_v3,.probootstrap_cover_v4 {
		height:inherit;
		text-align:center!important;
		padding:6em 0;
	}
}

.probootstrap_cover > .container,.probootstrap_cover_v1 > .container,.probootstrap_cover_v2 > .container,.probootstrap_cover_v3 > .container,.probootstrap_cover_v4 > .container {
	position:relative;
}



@media (max-width: 767px) {
	.probootstrap_cover > .container > .row,.probootstrap_cover_v1 > .container > .row,.probootstrap_cover_v2 > .container > .row,.probootstrap_cover_v3 > .container > .row,.probootstrap_cover_v4 > .container > .row {
		height:inherit;
		padding:6em 0;
	}
}

.probootstrap_cover .play,.probootstrap_cover_v1 .play,.probootstrap_cover_v2 .play,.probootstrap_cover_v3 .play,.probootstrap_cover_v4 .play {
	width:80px;
	height:80px;
	display:inline-block;
	position:relative;
	border:2px solid rgba(255,255,255,0.2);
	border-radius:50%;
	color:#fff;
	-webkit-transition:.25s all ease-out;
	-o-transition:.25s all ease-out;
	transition:.25s all ease-out;
}

.probootstrap_cover .play:hover,.probootstrap_cover_v1 .play:hover,.probootstrap_cover_v2 .play:hover,.probootstrap_cover_v3 .play:hover,.probootstrap_cover_v4 .play:hover {
	-webkit-transform:scale(1.2);
	-ms-transform:scale(1.2);
	transform:scale(1.2);
	border:2px solid #fff;
}

.probootstrap_cover .play:active,.probootstrap_cover .play:focus,.probootstrap_cover_v1 .play:active,.probootstrap_cover_v1 .play:focus,.probootstrap_cover_v2 .play:active,.probootstrap_cover_v2 .play:focus,.probootstrap_cover_v3 .play:active,.probootstrap_cover_v3 .play:focus,.probootstrap_cover_v4 .play:active,.probootstrap_cover_v4 .play:focus {
	outline:none;
}

.probootstrap_cover .play i,.probootstrap_cover_v1 .play i,.probootstrap_cover_v2 .play i,.probootstrap_cover_v3 .play i,.probootstrap_cover_v4 .play i {
	font-size:50px;
	position:absolute;
	top:50%;
	left:55%;
	-webkit-transform:translateX(-50%) translateY(-50%);
	-ms-transform:translateX(-50%) translateY(-50%);
	transform:translateX(-50%) translateY(-50%);
}

.probootstrap_cover .heading,.probootstrap_cover_v1 .heading,.probootstrap_cover_v2 .heading,.probootstrap_cover_v3 .heading,.probootstrap_cover_v4 .heading {
	font-size:40px;
	color:#fff;
	font-weight:300;
	margin-top:20px;
	margin-bottom:0;
}

@media (max-width: 767px) {
	.probootstrap_cover .heading,.probootstrap_cover_v1 .heading,.probootstrap_cover_v2 .heading,.probootstrap_cover_v3 .heading,.probootstrap_cover_v4 .heading {
		font-size:32px;
	}
}

.probootstrap_cover .sub-heading,.probootstrap_cover_v1 .sub-heading,.probootstrap_cover_v2 .sub-heading,.probootstrap_cover_v3 .sub-heading,.probootstrap_cover_v4 .sub-heading {
	font-size:20px;
	color:rgba(255,255,255,0.8);
	max-width:600px;
	float:left;
}

.probootstrap_cover.text-center .sub-heading,.probootstrap_cover_v1.text-center .sub-heading,.probootstrap_cover_v2.text-center .sub-heading,.probootstrap_cover_v3.text-center .sub-heading,.probootstrap_cover_v4.text-center .sub-heading {
	margin:0 auto;
	float:none;
}

.probootstrap_cover.text-right .sub-heading,.probootstrap_cover_v1.text-right .sub-heading,.probootstrap_cover_v2.text-right .sub-heading,.probootstrap_cover_v3.text-right .sub-heading,.probootstrap_cover_v4.text-right .sub-heading {
	float:right;
}

.probootstrap_cover.text-left .sub-heading,.probootstrap_cover_v1.text-left .sub-heading,.probootstrap_cover_v2.text-left .sub-heading,.probootstrap_cover_v3.text-left .sub-heading,.probootstrap_cover_v4.text-left .sub-heading {
	float:left;
}

.probootstrap_cover_v3 .device-1 {
	margin-top:-40%;
	max-width:400px;
	left:50%;
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}

@media (max-width: 991px) {
	.probootstrap_cover_v3 .device-1 {
		max-width:100%;
	}
}

@media (max-width: 767px) {
	.probootstrap_cover_v3 .device-1 {
		max-width:100%;
		position:relative;
		margin-top:10%;
		margin-bottom:-100%;
	}
}

.probootstrap_cover_v3 .device-2 {
	position:absolute;
	max-width:400px;
	left:50%;
	margin-top:-25%;
}

@media (max-width: 991px) {
	.probootstrap_cover_v3 .device-2 {
		max-width:100%;
	}
}

@media (max-width: 767px) {
	.probootstrap_cover_v3 .device-2 {
		max-width:100%;
		position:absolute;
		margin-top:30%;
		margin-bottom:-100%;
	}
}

.probootstrap_cover_v3 .device-3 {
	z-index:3;
}

.probootstrap_cover_v4 {
	height:inherit;
	padding-bottom:15em;
}

.probootstrap_cover_v4 .devices {
	margin-top:-12%;
}

@media (max-width: 991px) {
	.probootstrap_cover_v4 .devices {
		margin-top:35%;
	}
}

.probootstrap_cover_v4 .device-2,.probootstrap_cover_v4 .device-3 {
	position:absolute;
	max-width:400px;
	z-index:1;
}

.probootstrap_cover_v4 .device-2 {
	left:0;
}

@media (max-width: 767px) {
	.probootstrap_cover_v4 .device-2 {
		left:-20%;
	}
}

.probootstrap_cover_v4 .device-3 {
	right:0;
}

@media (max-width: 767px) {
	.probootstrap_cover_v4 .device-3 {
		right:-20%;
	}
}

.probootstrap_cover_v4 .device-1 {
	z-index:2;
	max-width:500px;
	left:50%;
	margin-top:-100px;
	margin-bottom:200px;
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}

@media (max-width: 767px) {
	.probootstrap_cover_v4 .device-1 {
		max-width:300px;
	}
}

.cover-bg-black,.cover-bg-white,.cover-bg-blue,.cover-bg-indigo,.cover-bg-purple,.cover-bg-pink,.cover-bg-red,.cover-bg-orange,.cover-bg-yellow,.cover-bg-green,.cover-bg-cyan {
	position:relative;
}

.cover-bg-black:before,.cover-bg-white:before,.cover-bg-blue:before,.cover-bg-indigo:before,.cover-bg-purple:before,.cover-bg-pink:before,.cover-bg-red:before,.cover-bg-orange:before,.cover-bg-yellow:before,.cover-bg-green:before,.cover-bg-cyan:before {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	content:"";
}

.cover-bg-opacity:before {
	opacity:1;
}

.cover-bg-opacity-1:before {
	opacity:.1;
}

.cover-bg-opacity-2:before {
	opacity:.2;
}

.cover-bg-opacity-3:before {
	opacity:.3;
}

.cover-bg-opacity-4:before {
	opacity:.4;
}

.cover-bg-opacity-5:before {
	opacity:.5;
}

.cover-bg-opacity-6:before {
	opacity:.6;
}

.cover-bg-opacity-7:before {
	opacity:.7;
}

.cover-bg-opacity-8:before {
	opacity:.8;
}

.cover-bg-opacity-9:before {
	opacity:.9;
}

.cover-bg-black:before {
	background:#000;
}

.cover-bg-white:before {
	background:#fff;
}

.cover-bg-blue:before {
	background:#1d82ff;
}

.cover-bg-indigo:before {
	background:#4e3188;
}

.cover-bg-purple:before {
	background:#715cb0;
}

.cover-bg-pink:before {
	background:#ff5b9c;
}

.cover-bg-red:before {
	background:#ee0427;
}

.cover-bg-orange:before {
	background:#ef6c35;
}

.cover-bg-yellow:before {
	background:#ffd500;
}

.cover-bg-green:before {
	background:#00ca4c;
}

.cover-bg-cyan:before {
	background:#a5e9db;
}

.probootstrap_gradient_v1:before {
	background:#665fee;
/* Old browsers */
	background:-moz-linear-gradient(45deg,#665fee 0%,#27c2de 100%);
/* FF3.6-15 */
	background:-webkit-linear-gradient(45deg,#665fee 0%,#27c2de 100%);
/* Chrome10-25,Safari5.1-6 */
	background:-o-linear-gradient(45deg,#665fee 0%,#27c2de 100%);
	background:linear-gradient(45deg,#665fee 0%,#27c2de 100%);
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#665fee',endColorstr='#27c2de',GradientType=1);
/* IE6-9 fallback on horizontal gradient */
	content:"";
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
}

.overlay {
	position:absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;
	background:rgba(0,0,0,0.4);
}

.probootstrap_form_v1 {
	padding:50px;
	-webkit-box-shadow:1px 11px 68px -20px rgba(0,0,0,0.75);
	-moz-box-shadow:1px 11px 68px -20px rgba(0,0,0,0.75);
	box-shadow:1px 11px 68px -20px rgba(0,0,0,0.75);
}

@media (max-width: 991px) {
	.probootstrap_form_v1 {
		padding:30px;
	}
}

input,select,textarea {
	font-weight:300;
	color:rgba(0,0,0,0.7);
}

.probootstrap_select-wrap {
	position:relative;
}

.probootstrap_select-wrap:before {
	font-family:'Ionicons';
	speak:none;
	font-style:normal;
	font-weight:400;
	font-variant:normal;
	text-transform:none;
	line-height:1;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	content:"\f3d0";
	position:absolute;
	right:20px;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	font-size:26px;
}

.probootstrap_select-wrap select {
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

.form-control.reverse {
	background:#f2f2f2;
	-webkit-transition:.3s all ease;
	-o-transition:.3s all ease;
	transition:.3s all ease;
	border-color:#f2f2f2;
}

.form-control.reverse:focus,.form-control.reverse:active {
	background:none;
	border-color:#aecf02!important;
}

.form-control-outline {
	background:none;
}

.form-control-outline:focus,.form-control-outline:active {
	background:none;
}

.form-control-outline.light {
	color:#fff;
	border-color:rgba(255,255,255,0.4);
}

.form-control-outline.light:focus,.form-control-outline.light:active {
	border-color:#fff;
}

.form-control-outline.light::-webkit-input-placeholder {
	color:rgba(255,255,255,0.5);
}

.form-control-outline.light:-ms-input-placeholder {
	color:rgba(255,255,255,0.5);
}

.form-control-outline.light::-ms-input-placeholder {
	color:rgba(255,255,255,0.5);
}

.form-control-outline.light::placeholder {
	color:rgba(255,255,255,0.5);
}

.form-control-outline.dark {
	color:#868e96;
}

.btn:focus,.btn:active {
	-webkit-box-shadow:none!important;
	box-shadow:none!important;
	outline:none!important;
}

.btn-shadow-blue {
	-webkit-box-shadow:0 15px 28px -5px rgba(29,130,255,0.45);
	-moz-box-shadow:0 15px 28px -5px rgba(29,130,255,0.45);
	box-shadow:0 15px 28px -5px rgba(29,130,255,0.45);
}

.probootstrap_btn-pill {
	border-radius:50px;
	padding-left:40px;
	padding-right:40px;
}

.probootstrap_outline-light {
	border:1px solid rgba(255,255,255,0.2);
	text-transform:uppercase;
	background:none;
	color:#fff;
}

.probootstrap_outline-light:hover,.probootstrap_outline-light:active,.probootstrap_outline-light:focus {
	outline:none;
	background:#fff!important;
	border:1px solid #fff;
	color:#000;
}

.probootstrap_outline-dark {
	border:1px solid rgba(0,0,0,0.2);
	text-transform:uppercase;
	color:#000;
	background:none;
}

.probootstrap_outline-dark:hover,.probootstrap_outline-dark:active,.probootstrap_outline-dark:focus {
	outline:none;
	background:#000!important;
	border:1px solid #000;
	color:#fff;
}

.probootstrap_signle-item {
	margin-bottom:50px;
	float:left;
	width:100%;
}

.probootstrap_signle-item img {
	-webkit-transition:.3s all;
	-o-transition:.3s all;
	transition:.3s all;
	position:relative;
	top:0;
}

.probootstrap_signle-item:focus img,.probootstrap_signle-item:hover img {
	top:-20px;
}

.probootstrap_thumbnail_v1 {
	display:block;
	position:relative;
	overflow:hidden;
	float:left;
	width:100%;
	margin-bottom:30px;
}

.probootstrap_thumbnail_v1 .probootstrap_media {
	position:relative;
	-webkit-transition:.3s all ease;
	-o-transition:.3s all ease;
	transition:.3s all ease;
	bottom:0;
}

.probootstrap_thumbnail_v1 .probootstrap_media:before {
	content:"";
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background:rgba(0,0,0,0.3);
	z-index:2;
}

.probootstrap_thumbnail_v1 .probootstrap_media .probootstrap_img-wrap {
	overflow:hidden;
}

.probootstrap_thumbnail_v1 .probootstrap_media .probootstrap_img-wrap img {
	position:relative;
	-webkit-transition:.5s all ease;
	-o-transition:.5s all ease;
	transition:.5s all ease;
	-webkit-transform:scale(1.09);
	-ms-transform:scale(1.09);
	transform:scale(1.09);
}

.probootstrap_thumbnail_v1 .probootstrap_media .btn {
	position:absolute;
	width:100%;
}

.probootstrap_thumbnail_v1 .text {
	position:absolute;
	bottom:10px;
	z-index:2;
	left:20px;
	-webkit-transition:.3s all ease;
	-o-transition:.3s all ease;
	transition:.3s all ease;
}

.probootstrap_thumbnail_v1 .text .heading {
	color:#fff;
	margin-bottom:0;
	margin-top:0;
	font-size:26px;
}

.probootstrap_thumbnail_v1 .text p {
	margin-bottom:0;
	color:rgba(255,255,255,0.7);
}

.probootstrap_thumbnail_v1:hover .text,.probootstrap_thumbnail_v1:focus .text {
	bottom:65px;
}

.probootstrap_thumbnail_v1:hover .probootstrap_media,.probootstrap_thumbnail_v1:focus .probootstrap_media {
	bottom:54px;
}

.probootstrap_thumbnail_v1:hover .probootstrap_media .probootstrap_img-wrap img,.probootstrap_thumbnail_v1:focus .probootstrap_media .probootstrap_img-wrap img {
	-webkit-transform:scale(1);
	-ms-transform:scale(1);
	transform:scale(1);
}

.probootstrap_blog_v1 {
	float:left;
	width:100%;
}

.probootstrap_blog_v1 .card-title {
	font-size:30px;
}

.probootstrap_blog_v1 .card-meta {
	font-size:14px;
	color:#ced4da;
}

.probootstrap_blog_v1 .card-meta a {
	color:#ced4da;
}

.probootstrap_blog_v1 .card-meta a:hover,.probootstrap_blog_v1 .card-meta a:focus,.probootstrap_blog_v1 .card-meta a:active {
	color:#212529;
}

.probootstrap_cards > .card {
	border-radius:0;
	padding:30px;
}

@media (max-width: 991px) {
	.probootstrap_cards > .card {
		padding:15px;
	}
}

#probootstrap_loader {
	position:fixed;
	width:96px;
	height:96px;
	left:50%;
	top:50%;
	-webkit-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	background-color:rgba(255,255,255,0.9);
	-webkit-box-shadow:0 24px 64px rgba(0,0,0,0.24);
	box-shadow:0 24px 64px rgba(0,0,0,0.24);
	border-radius:16px;
	opacity:0;
	visibility:hidden;
	-webkit-transition:opacity .2s ease-out,visibility 0 linear .2s;
	-o-transition:opacity .2s ease-out,visibility 0 linear .2s;
	transition:opacity .2s ease-out,visibility 0 linear .2s;
	z-index:1000;
}

#probootstrap_loader.fullscreen {
	padding:0;
	left:0;
	top:0;
	width:100%;
	height:100%;
	-webkit-transform:none;
	-ms-transform:none;
	transform:none;
	background-color:#fff;
	border-radius:0;
	-webkit-box-shadow:none;
	box-shadow:none;
}

#probootstrap_loader.show {
	-webkit-transition:opacity .4s ease-out,visibility 0 linear 0;
	-o-transition:opacity .4s ease-out,visibility 0 linear 0;
	transition:opacity .4s ease-out,visibility 0 linear 0;
	visibility:visible;
	opacity:1;
}

#probootstrap_loader .circular {
	-webkit-animation:loader-rotate 2s linear infinite;
	animation:loader-rotate 2s linear infinite;
	position:absolute;
	left:calc(50% - 24px);
	top:calc(50% - 24px);
	display:block;
	-webkit-transform:rotate(0deg);
	-ms-transform:rotate(0deg);
	transform:rotate(0deg);
}

#probootstrap_loader .path {
	stroke-dasharray:1,200;
	stroke-dashoffset:0;
	-webkit-animation:loader-dash 1.5s ease-in-out infinite;
	animation:loader-dash 1.5s ease-in-out infinite;
	stroke-linecap:round;
}

@-webkit-keyframes loader-rotate {
	100% {
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}

@keyframes loader-rotate {
	100% {
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}

@-webkit-keyframes loader-dash {
	0% {
		stroke-dasharray:1,200;
		stroke-dashoffset:0;
	}
	
	50% {
		stroke-dasharray:89,200;
		stroke-dashoffset:-35px;
	}
	
	100% {
		stroke-dasharray:89,200;
		stroke-dashoffset:-136px;
	}
}

@keyframes loader-dash {
	0% {
		stroke-dasharray:1,200;
		stroke-dashoffset:0;
	}
	
	50% {
		stroke-dasharray:89,200;
		stroke-dashoffset:-35px;
	}
	
	100% {
		stroke-dasharray:89,200;
		stroke-dashoffset:-136px;
	}
}

html {
	overflow-x:hidden;
	font-size:1em;
}

body {
	font-family:"Montserrat",Arial,sans-serif;
}

input,textarea,p {
	font-weight:300;
}

::-moz-selection {
	background:#000;
	color:#fff;
}

::selection {
	background:#000;
	color:#fff;
}

::-moz-selection {
	background:#000;
	color:#fff;
}

h1,h2,h3,h4,h5,h6 {
	font-weight:300!important;
}

a {
	-webkit-transition:.3s all ease;
	-o-transition:.3s all ease;
	transition:.3s all ease;
}

.probootstrap_navbar {
	position:absolute;
	top:0;
	left:0;
	z-index:100;
	width:100%;
	font-family:"Montserrat",Arial,sans-serif;
	background:#fff!important;
	-webkit-box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
	-moz-box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
	box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
}

.probootstrap_navbar .navbar-toggler:active,.probootstrap_navbar .navbar-toggler:focus {
	outline:none;
}

.probootstrap_navbar > .container {
	position:relative;
}

.probootstrap_navbar .navbar-toggler {
	border:none;
	color:#000;
	border-color:#fff;
	cursor:pointer;
	padding-right:0;
	position: absolute;
	left: 0px;
	top: 20px;
}

.probootstrap_navbar .navbar-toggler i {
	font-size:30px;
}

.probootstrap_navbar ul > li > a {
	color:#000!important;
}

.probootstrap_navbar ul > li > a:hover {
	color:#aecf02 !important;
}

.probootstrap_navbar ul > li > a.active {
	color:#000!important;
}

.probootstrap_navbar ul > li.cta-btn > a > span {
	padding:10px;
	border:1px solid rgba(255,255,255,0.5);
}

.probootstrap_navbar .nav-link i {
	margin-right: 4px;
	color: #8ca608;
}

/***************************************	MEDIA
****************************************/ 

/* Large desktops and laptops */

@media (min-width: 1200px) {
	.probootstrap_navbar .nav-link {
		font-size:14px;
		padding-left:0.5rem!important;
		padding-right:0.5rem!important;
		text-transform:uppercase;
	}
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.probootstrap_navbar .nav-link {
		font-size:12px;
		padding-left:0.3rem!important;
		padding-right:0.3rem!important;
		text-transform:uppercase;
	}
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
	.probootstrap_navbar .nav-link {
		font-size:12px;
		padding-left:0.3rem!important;
		padding-right:0.3rem!important;
		text-transform:uppercase;
	}
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	.navbar-collapse li {
		border-top: 1px solid #c1c0c0;
	}
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
	
}
 

@media (max-width: 991px) {
	.probootstrap_navbar .navbar-nav {
		border-radius:0;
		padding-left:1rem!important;
		padding-right:1rem!important;
	}
}

.probootstrap_navbar.scrolled {
	position:fixed;
	right:0;
	left:0;
	top:0;
	margin-top:-130px;
}

@media (max-width: 991px) {
	.probootstrap_navbar.scrolled .navbar-nav {
		background:none;
		border-radius:0;
		padding-left:0!important;
		padding-right:0!important;
	}
}

@media (max-width: 767px) {
	.probootstrap_navbar.scrolled .navbar-nav {
		background:none;
		padding-left:0!important;
		padding-right:0!important;
	}
}

.probootstrap_navbar.scrolled .navbar-toggler {
	border:none;
	color:rgba(0,0,0,0.5);
	border-color:rgba(0,0,0,0.5);
	cursor:pointer;
	padding-right:0;
}

.probootstrap_navbar.scrolled .nav-link {
	padding-top:.5rem;
	padding-bottom:.5rem;
}

.probootstrap_navbar.scrolled.awake {
	margin-top:0;
	-webkit-transition:.3s all ease-out;
	-o-transition:.3s all ease-out;
	transition:.3s all ease-out;
}

.probootstrap_navbar.scrolled.sleep {
	-webkit-transition:.3s all ease-out;
	-o-transition:.3s all ease-out;
	transition:.3s all ease-out;
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light {
	background:#fff!important;
	-webkit-box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
	-moz-box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
	box-shadow:0 1px 10px -2px rgba(0,0,0,0.15);
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light .navbar-brand {
	color:#aecf02;
}

.logo img {
	width: 200px;
}

@media (max-width: 991px) {
	.probootstrap_navbar.scrolled.probootstrap_scrolled-light .navbar-nav {
		background:none;
		border-radius:0;
		padding-left:0!important;
		padding-right:0!important;
	}
	
}
@media (max-width: 768px) {
	.logo {
		width: 200px;
		margin: auto;
		text-align: center;
	}
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li a {
	color:#212529!important;
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li a:hover,.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li a:active,.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li a:focus {
	color:#868e96!important;
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li a.active {
	color:#aecf02!important;
}

.probootstrap_navbar.scrolled.probootstrap_scrolled-light ul li.cta-btn > a > span {
	border:none;
	background:#00CA4C;
	color:#fff;
}

.probootstrap_offcanvas-nav_v1 {
	position:fixed;
	left:0;
	right:0;
	bottom:0;
	top:0;
	z-index:99;
	opacity:0;
	visibility:hidden;
	background:#000;
	-webkit-transition:.3s all ease-in-out;
	-o-transition:.3s all ease-in-out;
	transition:.3s all ease-in-out;
}

.probootstrap_offcanvas-nav_v1.active {
	opacity:1;
	visibility:visible;
}

.probootstrap_offcanvas-nav_v1 .probootstrap_main-nav {
	padding-top:50px;
	padding-right:50px;
	float:right;
	text-align:right;
	font-size:30px;
	height:100vh;
	overflow-y:auto;
}

.probootstrap_offcanvas-nav_v1 .probootstrap_main-nav li {
	list-style:none;
	line-height:1.5;
	margin-bottom:10px;
}

.probootstrap_offcanvas-nav_v1 .probootstrap_main-nav li a {
	color:#fff;
}

.probootstrap_offcanvas-nav_v1 .probootstrap_main-nav li a.active {
	text-decoration:underline;
}

.probootstrap_offcanvas-header_v1 {
	padding-top:40px;
	z-index:2;
	position:absolute;
	width:100%;
}

.probootstrap_offcanvas-header_v1 > .container {
	position:relative;
}

.probootstrap_offcanvas-header_v1 .probootstrap_site-name {
	position:absolute;
	left:15px;
}

.probootstrap_offcanvas-header_v1 .probootstrap_toggle-menu {
	position:absolute;
	right:0;
	top:17px;
}

@media (max-width: 767px) {
	.probootstrap_offcanvas-header_v1 .probootstrap_toggle-menu {
		top:13px;
	}
}

#probootstrap_nav-toggle {
	background:transparent;
	position:absolute;
	right:0;
	top:50%;
	height:50px;
	width:50px;
	cursor:pointer;
	-webkit-transform:translate(0%,-50%);
	-ms-transform:translate(0%,-50%);
	transform:translate(0%,-50%);
}

#probootstrap_nav-toggle:hover span:after {
	width:32px;
}

#probootstrap_nav-toggle span,#probootstrap_nav-toggle span:before,#probootstrap_nav-toggle span:after {
	cursor:pointer;
	border-radius:1px;
	height:2px;
	width:32px;
	background:#000;
	position:absolute;
	left:0;
	top:50%;
	display:block;
	content:'';
}

#probootstrap_nav-toggle span:before {
	top:-10px;
}

#probootstrap_nav-toggle span:after {
	top:10px;
	width:16px;
}

#probootstrap_nav-toggle span,#probootstrap_nav-toggle span:before,#probootstrap_nav-toggle span:after {
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out;
}

#probootstrap_nav-toggle.active span {
	background-color:transparent;
}

#probootstrap_nav-toggle.active span:before,#probootstrap_nav-toggle.active span:after {
	top:0;
}

#probootstrap_nav-toggle.active span:before {
	-webkit-transform:rotate(135deg);
	-ms-transform:rotate(135deg);
	transform:rotate(135deg);
}

#probootstrap_nav-toggle.active span:after {
	width:32px;
	-webkit-transform:rotate(-135deg);
	-ms-transform:rotate(-135deg);
	transform:rotate(-135deg);
}

.btn-primary {
	padding:10px 25px;
}

.btn.btn-primary {
	color:#fff;
	background-color: #aecf02;
    border-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.btn:hover,.btn:active,.btn:focus {
	background-color: #000000;
	color: #ffffff;
}

.btn-previous {
	color:#000000;
	background-color: transparent;
    border: 1px solid #000000 !important;
    cursor: pointer;
    padding:10px 25px;
}

.bg_image,.probootstrap_sm_py_cover,.probootstrap_md_py_cover,.probootstrap_lg_py_cover,.probootstrap_xl_py_cover,.probootstrap_cover,.probootstrap_cover_v1,.probootstrap_cover_v2,.probootstrap_cover_v3,.probootstrap_cover_v4,.probootstrap-section-half .probootstrap-image {
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
}

.with-overlay {
	position:relative;
}

.with-overlay:before {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	content:"";
	background:rgba(0,0,0,0.4);
}

.probootstrap_navbar .nav-item.active > a {
	color:#aecf02!important;
}

.probootstrap_navbar.scrolled .nav-item.active > a {
	color:#aecf02!important;
}

.probootstrap_cover_v3 p,.probootstrap_cover_v3 .lead {
	color:#fff;
}

.probootstrap-section-half {
	float:left;
	width:100%;
}

.probootstrap-section-half .probootstrap-image,.probootstrap-section-half .probootstrap-text {
	width:50%;
}

@media (max-width: 991px) {
	.probootstrap-section-half .probootstrap-image,.probootstrap-section-half .probootstrap-text {
		width:100%;
	}
}

@media (max-width: 767px) {
	.probootstrap-section-half .probootstrap-image {
		height:400px;
	}
}

.probootstrap-section-half .probootstrap-text {
	background:#ECECEC;
}

.probootstrap-section-half .probootstrap-text .probootstrap-inner {
	padding:7em;
}

@media (max-width: 991px) {
	.probootstrap-section-half .probootstrap-text .probootstrap-inner {
		padding:3em;
	}
}

@media (max-width: 991px) {
	.probootstrap-section-half .probootstrap-text .probootstrap-inner {
		padding-left:15px;
		padding-right:15px;
	}
}

.probootstrap-media .read-more {
	text-align:center;
}

.probootstrap-media .read-more a {
	width:100px;
	height:30px;
	display:block;
	margin:0 auto;
	position:relative;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	-webkit-transition:.5s all ease;
	-o-transition:.5s all ease;
	transition:.5s all ease;
	background-color: #aecf02;
	color: #ffffff;
}
.probootstrap-media .read-more a:hover {
	background-color: #000000;
	color: #aecf02;
}

.probootstrap-media .read-more a span {
	top:50%;
	left:50%;
	line-height:0;
	position:absolute;
	-webkit-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}


.probootstrap_section {
	padding:5em 0;
	width:100%;
	position:relative;
	overflow:hidden;
}
.probootstrap_section_2 {
	padding:5em 0 2em 0;
	width:100%;
	position:relative;
	overflow:hidden;
}
.probootstrap_section_3 {
	 padding: 6em 0em 0em 0em;
	width:100%;
	position:relative;
	overflow:hidden;
}

.probootstrap_section_small {
	padding:2em 0;
	width:100%;
	position:relative;
	overflow:hidden;
}

.probootstrap_section.bg-light {
	background:#ECECEC!important;
}

.probootstrap_feature-v1 {
	padding:3em;
	-webkit-transition:.3s all ease;
	-o-transition:.3s all ease;
	transition:.3s all ease;
	position:relative;
	top:0;
	border-radius:4px;
}

.probootstrap_feature-v1:hover,.probootstrap_feature-v1:focus {
	top:-10px;
	background:#fff;
	-webkit-box-shadow:0 2px 10px 0 rgba(0,0,0,0.05);
	box-shadow:0 2px 10px 0 rgba(0,0,0,0.05);
}

@media (max-width: 991px) {
	.probootstrap_feature-v1:hover,.probootstrap_feature-v1:focus {
		top:0;
	}
}

.probootstrap-border-top {
	border-top:1px solid #ECECEC;
}

.probootstrap-form {
	margin-bottom:0;
	padding:40px;
	background:#fff;
	margin-bottom:50px;
	-webkit-box-shadow:0 2px 10px 0 rgba(0,0,0,0.05);
	box-shadow:0 2px 10px 0 rgba(0,0,0,0.05);
	border-radius:4px;
}

@media (max-width: 991px) {
	.probootstrap-form {
		padding:15px;
	}
}

.probootstrap-form label {
	font-weight:400;
}

.probootstrap-form .form-control {
	height:46px;
	-webkit-box-shadow:none;
	box-shadow:none;
	font-size:14px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	border-radius:4px;
	border:1px solid #d9d9d9;
}

.probootstrap-form .form-control:active,.probootstrap-form .form-control:focus {
	-webkit-box-shadow:none;
	box-shadow:none;
	border:1px solid #aecf02;
}

.probootstrap-form .form-group:last-child {
	margin-bottom:0;
	padding-bottom:0;
}

.probootstrap-form .form-group label {
	text-transform:uppercase;
	font-size:13px;
}

.probootstrap-form .form-group .form-field {
	position:relative;
}

.probootstrap-form .form-group .form-field .icon {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:20px;
}

.probootstrap-form .form-group .form-field .form-control {
	padding-right:50px;
}

.probootstrap-form textarea.form-control {
	height:inherit;
	resize:vertical;
}

.probootstrap-contact-details {
	padding:0;
	margin:0;
}

.probootstrap-contact-details li {
	padding:0;
	margin:0 0 30px;
	list-style:none;
}

.probootstrap-contact-details li > span {
	display:block;
	color:rgba(0,0,0,0.3);
	color:#aecf02;
}

.probootstrap-animate {
	opacity:0;
	visibility:hidden;
}

.bg_footer {
	background-size : cover;
	background-image:url('../img/footer.png');
	background-position:50% 50%;
	position:relative;
	padding: 4rem 0;
	color: #ffffff;
}

.footer_coord a {
	color: #ffffff;
	text-decoration: none;
}
.footer_coord a:hover {
	color: #aecf02;
	text-decoration: none;
}

.bg_footer .list-unstyled a {
	color: #ffffff;
	font-size: 0.8rem;
	border-left: 0px solid transparent;
	text-decoration: none;
}
.bg_footer .list-unstyled a:hover {
	letter-spacing: 1px;
	font-size: 0.8rem;
	border-left: 5px solid #aecf02;
	padding-left: 8px;
	text-decoration: none;
}
.bg_footer:before {
	content:"";
	background-color:rgba(4,4,4,0.35);
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left: 0;
}
.titre_1_home {
	font-size: 34px !important;
	text-transform: uppercase;
}
.titre_1 {
	font-size: 54px !important;
	text-transform: uppercase;
}
.titre_2, .titre_2_left  {
	text-transform: uppercase;
	font-size: 1.2rem;
	position: relative;
}
.titre_2:after {
	content: "";
	height: 5px;
	width: 90px;
	background-color: #aecf02;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -45px;
}
.titre_2_left:after {
	content: "";
	height: 5px;
	width: 90px;
	background-color: #aecf02;
	position: absolute;
	bottom: -10px;
	left: 0;
}

.titre_3 {
	font-size: 1.4rem;
	line-height: 1.4rem;
	margin-bottom: 25px;
	padding-bottom: 15px;
	position: relative;
}
.titre_3:after {
	content: "";
	height: 5px;
	width: 60px;
	background-color: #b5b5b5;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -30px;
}
.vert_fonce {
	color: #8ca608;
}


.backtotop_btn {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 99;
}

.backtotop_btn a {
	background-color: #aecf02;
	color: #ffffff;
	height: 40px;
	width: 40px;
	text-align: center;
	display: block;
}
.backtotop_btn a:hover {
	background-color: #8ca608;
}

.titre_marque {
	text-align: center;
	text-transform: uppercase;
	font-size: 20px;
	background-color: #aecf02;
	padding: 12px 0px;
	color: #ffffff;
}

.img_marque {
	display: block;
	cursor: pointer;
	border: 1px solid #aecf02;
	overflow: hidden;
}

.img_marque a{
	display: block;
	cursor: pointer;
	position: relative;
}
.img_marque a img {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.img_marque a:hover img{
	-webkit-transform: scale(2.2);
	-moz-transform: scale(2.2);
	-o-transform: scale(2.2);
	transform: scale(2.2);
}

.img_marque a:before {
	content: "\f067";
	font-family: 'Fontawesome';
	background-color: rgba(0, 0, 0, 0.4);
	height: 100%;
	width: 100%;
	font-size: 0px;
	position: absolute;
	z-index: 3;
	text-align: center;
	color: #ffffff;
	top: 150%;
	transform: translateY(-50%);
	padding-top: 40%;
}
.img_marque a:hover:before {
	content: "\f067";
	font-family: "Fontawesome";
	background-color: rgba(0, 0, 0, 0.4);
	height: 100%;
	width: 100%;
	font-size: 24px;
	position: absolute;
	z-index: 3;
	text-align: center;
	color: #ffffff;
	top: 50%;
	transform: translateY(-50%);
	padding-top: 40%;
}

.img_marque a img, .img_marque a:hover img {
	-webkit-transition: all 2s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	-o-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
}
.img_marque a:before, .img_marque a:hover:before {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

ul.liste_center {
	padding-left: 0px;
}
.liste_center li {
	text-align: center;
	list-style: none;
	padding: 6px 0px 6px 0px;
	position: relative;
	margin-bottom: 15px;
}
.liste_center li:before {
	content: "\f054";
	font-family: 'Fontawesome';
	position: relative;
	margin-right: 10px;
	color: #aecf02;
}

.liste_center li:after {
    content: "";
    height: 3px;
    width: 90px;
    background-color: #b5b5b5;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -45px;
}

.commentmarche_titre {
	min-height: 83px;
}
.commentmarche_desc {
	min-height: 66px;
}

.titre_produit {
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 30px;
	border-top: 5px solid #aecf02;
	padding-top: 10px;
}
.titre_produit:after {
    content: "";
    height: 5px;
    width: 90px;
    background-color: #b5b5b5;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -45px;
}
.img_produit {
	text-align: center;
	min-height: 170px;
	webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
.img_produit:hover {
	-webkit-transform: rotate(10deg);
	-moz-transform: rotate(10deg);
	-o-transform: rotate(10deg);
	transform: rotate(10deg);
}
.img_produit, .img_produit:hover {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.reprise_prix a {
	background-color: #aecf02;
	color: #ffffff;
	padding: 10px 0px;
	text-align: center;
	display: block;
	text-decoration: none;
}
.reprise_prix a:hover {
	background-color: #000000;
	color: #ffffff;
	padding: 10px 0px;
	text-align: center;
	display: block;
}

.form_vente_titre  {
	text-transform: uppercase;
	font-size: 1.5rem;
	position: relative;
	text-align: center;
	color: #aecf02;
	margin-bottom: 40px;
}
.form_vente_titre:after {
	content: "";
	height: 5px;
	width: 90px;
	background-color: #aecf02;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -45px;
}
.form_vente_titre_2  {
	text-transform: uppercase;
	font-size: 1rem;
	position: relative;
	text-align: center;
	color: #aecf02;
	margin-bottom: 40px;
}
.form_vente_titre_2:after {
	content: "";
	height: 5px;
	width: 90px;
	background-color: #aecf02;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -45px;
}

#section_form .form_vente_question {
	font-size: 16px;
	margin-bottom: 30px;
}

#section_form fieldset {
	border: 4px solid #aecf02;
	padding: 20px;
	margin-bottom: 20px;
}
#section_form legend {
	padding-left: 14px;
	padding-right: 14px;
	font-size: 1.4rem;
}
#section_form label {
	margin-right: 10px;
}

.form_vente_reponse {
	font-size: 12px;
	font-style: italic;
}


#section_form input[type="radio"] {
	height: 24px;
	width: 24px;
	border: 1px solid #000000;
	display: inline-block;
	margin-right: 10px;
}

#section_form input[type="radio"]:checked {
  margin: 0px 10px 0px 0px;
  position: relative;
}
#section_form input[type="radio"]:checked:before {
	font-family: "fontawesome";
	content: '\f00c';
	display: block;
	color: #aecf02;
	font-size: 30px;
	position: absolute;
	margin-top: -13px
}

#section_form input[type="radio"]:focus {
	outline: 0;
}

#section_form input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}
.tableau th {
	border-bottom: 4px solid #b5b5b5;
	text-transform: uppercase;
	padding: 10px 0px;
}

.tableau { 
	width: 100%; 
}
.tableau td {
	padding: 10px 0px;
	border-bottom: 1px solid rgba(181, 181, 181, 0.4);
}
.btn_tableau {
 	cursor : pointer;
	width: 100%;
	display: block;
	margin-top: 20px;
	font-size: 18px;
	color: #fff;
	background-color: #aecf02;
	border-color: rgba(255, 255, 255, 0.5);
	padding: 10px 25px;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.btn_tableau strong {
	font-size: 28px;
}

.btn_conseil a {
	display: block;
	padding: 20px;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
	background-color: #f2f2f2;
	color: #1f1f1f;
	text-decoration: none;
}
.btn_conseil a:hover {
	margin-top: -10px;
	box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
	text-decoration: none;
}
.btn_conseil a i {
	font-size: 60px;
	margin-bottom: 10px;
	color: #aecf02;
	display: block;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
.btn_conseil a:hover i {
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}

.btn_conseil a i, .btn_conseil a:hover i {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.etoile a:before {
	content: "\f005";
	font-family: "fontawesome";
	position: absolute;
	top: 6%;
	left: 14%;
	color: #000000;
	font-size: 20px;
}
.etoile a:after {
	content: "\f005";
	font-family: "fontawesome";
	position: absolute;
	top: 6%;
	right: 14%;
	color: #000000;
	font-size: 20px;
}

.form-group {
    margin-bottom: 15px;
}
.form-group input {
    width: 100%;
}
.form-control {
    background-color: #F3F2EE;
    border: 1px solid #e8e7e3;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    padding: 7px 18px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
}


/***************************************	MEDIA
****************************************/ 

/* Large desktops and laptops */

@media (min-width: 1200px) {
	.reponse {
		float: left;
	}
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.bg_footer {
		background-size: 150%;
	}
	.reponse {
		float: left;
	}
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
	.bg_footer {
		background-size: 600%;
	}
	.titre_2 {
		font-size: 1.5rem !important;
	}
	.titre_marque {
		font-size: 18px;
	}
	#commentmarche .col-md-3 {
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.reponse {
		display: block;
		width: 100%;
	}
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	.bg_footer {
		background-size: 800%;
	}
	.titre_1 {
		font-size: 40px !important;
	}
	.titre_2 {
		font-size: 18px !important;
	}
	.img_marque {
		margin-bottom: 1.5rem; 
	}
	.btn_conseil {
		margin-bottom: 30px;
		margin-right: 15px;
		margin-left: 15px;
	}
	#section_form fieldset {
		margin-right: 15px;
		margin-left: 15px;
	}
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
	
}




.navbar-nav .dropdown-menu {
	width: 300px
}

.card-container.card {
    max-width: 300px;
    padding: 5px 10px;
}

.dropdown-menu.show {
	padding: 0rem;
}
/*
 * Card component
 */
.card {
    background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 15px auto 15px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    height: 44px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(104, 145, 162);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
}

.forgot-password, .newcLt {
    color: #b5b5b5;
    font-size: 12px;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: #000000;
}

.newcLt:hover,
.newcLt:active,
.newcLt:focus{
    color: #000000;
}

.form-signin .checkbox label {
	font-size: 12px;
}

.picto_reassurance { 
	background-color: #aecf02;
	color: #ffffff;
	height: 90px;
	width: 90px;
	line-height: 90px;
	text-align: center;
	margin: auto;
	font-size: 26px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin-bottom: 20px;
}
.titre_reassurance {
	text-transform: uppercase;
}

.rox_reassurance {
	border-top: 1px solid #c3c0c0;
	border-bottom: 1px solid #c3c0c0;
	padding: 30px 0px;
	background-color: #ECECEC;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}
.btn_go {
	background-color: #5a5a5a;
	color: #ffffff;
	-webkit-border-radius: 0px 5px 5px 0px;
	-moz-border-radius: 0px 5px 5px 0px;
	border-radius: 0px 5px 5px 0px;
	padding: 10px 10px;
}

#section_recap .btn_conseil {
	font-size: 12px;
}
#section_recap .btn_conseil a{
	min-height: 170px;
}

.recap th {
	padding: 14px;
}
.recap th:first-child {
	width: 70%;
}
.recap td {
	padding: 14px;
}

.recap tr:last-child {
	background-color: #ECECEC;
	font-weight: bold;
}
.recap tr:first-child {
	background-color: transparent;
	font-weight: normal;
}

.transport_selected {
    border: solid thin green;
}
.paiement_selected {
    border: solid thin green;
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
}
.checkbox input[type="radio"], input[type="checkbox"] {
    margin-right: 6px;
}

.row .page h1, .row .page h2, .row .page h3
{
	margin-bottom: 50px;
}



